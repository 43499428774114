<template>
  <v-app id="app">
    <div
      v-if="!publicPages.includes(currentPath) && authorized"
      class="not-public"
      :class="{
        'scroll-pages': scrollPages.includes(this.$router.currentRoute.name),
      }"
    >
      <TopBar
        :drawer="drawer"
        :mini="mini"
        @change-drawer="handleChangeDrawer"
      />
      <Navigation
        :mini="mini"
        :drawer="drawer"
        @change-drawer="handleChangeDrawer"
      />
      <v-main
        :class="{
          'main-content': validatePath,
        }"
      >
        <v-container
          fluid
          class="app-content"
          :class="{
            'overflow-visible': $router.currentRoute.name === 'chart-generator',
          }"
        >
          <router-view
            :drawerApp="drawer"
            @change-drawer="handleChangeDrawer"
            :key="$route.fullPath"
          />
        </v-container>
        <v-footer class="footer">
          <FooterBar />
        </v-footer>
      </v-main>
    </div>
    <div v-if="publicPages.includes(currentPath)" class="public">
      <v-main
        v-if="
          ['reset-password', 'login', 'forgot-password'].includes(
            this.$router.currentRoute.name
          )
        "
      >
        <router-view />
        <v-footer>
          <FooterBar />
        </v-footer>
      </v-main>
      <!--      rate-chart-->
      <router-view v-else />
    </div>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import markerSDK from '@marker.io/browser';

import TopBar from '@/components/TopBar/TopBar.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import FooterBar from '@/components/FooterBar/FooterBar.vue';

import {
  MARKER_IO_IDS,
  NAMES_PUBLIC_PAGES,
  PUBLIC_PAGES,
} from '@/utils/constants';
import { startTimer } from '@/utils/helpers';

@Component({
  components: { TopBar, Navigation, FooterBar },
  computed: {
    ...mapGetters({
      allProjects: 'projects/getAllProjects',
      isAuthorized: 'auth/isAuthorized',
      authLoading: 'auth/getLoading',
      isAuthorizationExpired: 'auth/isAuthorizationExpired',
      bankDetails: 'bankingProfile/getBankDetails',
    }),
  },
  methods: {
    ...mapActions({
      authCheck: 'auth/authCheck',
    }),
  },
})
export default class App extends Vue {
  drawer = true;
  mini = false;
  isAuthorized!: boolean;
  currentPath = this.$router.currentRoute.fullPath;
  authCheck!: ({ page }) => Promise<Object>;
  bankDetails!: { anbietername?: string };

  mainContentPages: Array<string> = [
    '/:producttype/wizard/:id',
    '/:producttype/wizard',
    '',
  ];
  scrollPages = [
    'bankingProfileReport',
    'bankingProfileNewReport',
    'wizardEditReport',
    'wizard',
    'report',
    'benchmark',
  ];
  publicPages: string[] = PUBLIC_PAGES;

  get validatePath() {
    //eslint-disable-next-line
    return this.$router.currentRoute.matched?.[0] ? this.mainContentPages.includes(this.$router.currentRoute.matched[0].path) : false;
  }

  @Watch('isAuthorizationExpired')
  @Watch('isAuthorized')
  setTimer(val: boolean) {
    if (val) {
      const expiresAt = localStorage.getItem('expiresAt') || '';
      if (expiresAt) {
        const expire = JSON.parse(expiresAt);
        const expiresAtTime = Number(
          String(new Date(expire.split('+')[0]).getTime()).slice(0, 10)
        );

        const currentTime = Number(String(Date.now()).slice(0, 10));
        const expireTime = (expiresAtTime - currentTime) * 1000;
        startTimer(expireTime, this.$router.currentRoute.fullPath);
      }
    }
  }

  @Watch('bankDetails')
  @Watch('$route')
  async checkAuthorizedAndSetPath() {
    const locale = JSON.parse(
      localStorage.getItem('language') || JSON.stringify('de')
    );
    this.setDocumentTitle(locale);

    if (
      !this.isAuthorized &&
      !(NAMES_PUBLIC_PAGES as any).includes(this.$router.currentRoute.name)
    ) {
      await this.authCheck({ page: this.$router.currentRoute.fullPath });
    }
    if (this.$router.currentRoute.name === 'rate-chart') {
      this.currentPath = '/rate-chart';
    } else {
      this.currentPath = this.$router.currentRoute.fullPath;
    }
  }

  @Watch('$i18n.locale')
  widgetAndDocTitle(locale: string) {
    const projectId = locale === 'de' ? MARKER_IO_IDS.de : MARKER_IO_IDS.en;
    this.loadWidget(projectId);
    this.setDocumentTitle(locale);
  }
  setDocumentTitle(locale: string) {
    if (!this.$router.currentRoute.meta?.title) {
      document.title = 'Radar';
    }
    if (
      this.$router.currentRoute.meta?.title &&
      this.$router.currentRoute.name &&
      this.$router.currentRoute.name !== 'bankDetails'
    ) {
      document.title =
        locale === 'de'
          ? this.$router.currentRoute.meta?.title
          : this.$router.currentRoute.meta?.titleEn
          ? this.$router.currentRoute.meta.titleEn
          : this.$router.currentRoute.meta?.title;
    }
    if (
      this.bankDetails.anbietername &&
      this.$router.currentRoute.name &&
      this.$router.currentRoute.name === 'bankDetails'
    ) {
      document.title =
        locale === 'de'
          ? `Radar - Anbieterprofile ${this.bankDetails.anbietername}`
          : `Radar - Provider Profiles ${this.bankDetails.anbietername}`;
    }
  }
  loadWidget(projectId: string) {
    if (
      process.env.NODE_ENV === 'production' &&
      !window.location.origin.includes('dev')
    ) {
      markerSDK.loadWidget({
        project: projectId,
      });
    }
  }
  handleChangeDrawer(val) {
    this.drawer = val.drawer;
    this.mini = val.mini;
  }

  get authorized() {
    return process.env.VUE_APP_IS_DEVELOPMENT === 'true'
      ? true
      : this.isAuthorized;
  }
  mounted() {
    const locale = JSON.parse(
      localStorage.getItem('language') || JSON.stringify('de')
    );
    if (locale === 'de') {
      this.setDocumentTitle('de');
      this.loadWidget(MARKER_IO_IDS.de);
    }
  }
}
</script>

<style lang="scss">
.dashboard-page {
  height: 100% !important;
}

.main-content {
  & > .v-main__wrap {
    background-color: $main-background;
  }
}

.table_shadow {
  width: 100% !important;
}

.v-data-table__wrapper {
  overflow-y: initial !important;
  overflow-x: initial !important;
}
.public {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $main-background;
}
.not-public {
  display: flex;
  flex-direction: column;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  max-width: 100%;
  position: relative;
  height: 100vh;
  .app-content {
    overflow: hidden;
  }
  .overflow-visible {
    overflow: visible;
  }
  [class~='v-main'] {
    height: 100vh;
  }
  [class~='v-main__wrap'] {
    overflow-y: auto;
  }
  .footer {
    overflow: hidden;
  }
}

.scroll-pages {
  .app-content {
    overflow: initial !important;
  }
  [class~='v-main__wrap'] {
    overflow-x: auto;
    background-color: $background;
  }
}

@media screen and (max-width: 376px) {
  body {
    overflow-y: scroll;
  }
}
@media screen and (max-height: 376px) {
  body {
    overflow-y: scroll;
  }
}
@media screen and (max-height: 414px) {
  body {
    overflow-y: scroll;
  }
}
</style>
