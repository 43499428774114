import Vuetify from 'vuetify';
import VueDayjs from 'vue-dayjs-plugin';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueMatomo from 'vue-matomo';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import iframeResize from './plugins/iFrameResizer';
import { Tooltip } from 'chart.js';

// @ts-ignore
import App from './App.vue';
import router from './router.js';
import { store } from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import FlagIcon from 'vue-flag-icon';
import MultiFiltersPlugin from '@/plugins/multiFilters';
import './filters';
import apolloProvider from './apollo/apollo-provider';

// @ts-ignore
import { MATOMO_SITE_ID, MATOMO_HOST, API_KEY } from './utils/constants';

// @ts-ignore
import mixins from '@/mixins.js';

(Tooltip.positioners as any).custom = function (elements, position) {
  if (!elements.length) {
    return false;
  }
  let offset = 0;
  //adjust the offset left or right depending on the event position
  if (elements[0].element.x / 2 < position?.chart?.width) {
    offset = 35;
  } else {
    offset = -35;
  }
  return {
    x: elements[0].element.x + offset,
    y: elements[0].element.y - 10,
  };
};

Vue.config.productionTip = false;
axios.defaults.headers.common['ApiKey'] = API_KEY; //for wettbewerb

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://393688aed075406daf6689d405b0e097@o828031.ingest.sentry.io/5817385',
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
  });
}

Vue.directive('resize', {
  bind: function (el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el));
  },
  unbind: function (el) {
    // @ts-ignore
    el?.iFrameResizer?.removeListeners();
  },
});

// @ts-ignore
Vue.use(Vuelidate);
Vue.use(FlagIcon);
Vue.use(MultiFiltersPlugin);
Vue.use(Vuetify);
Vue.use(VueDayjs);

Vue.use(VueMatomo, {
  host: MATOMO_HOST,
  trackerFileName: 'matomo',
  //trackerScriptUrl: `${process.env.VUE_APP_MATOMO_TRACKER_ENDPOINT}`,
  siteId: `${MATOMO_SITE_ID}`,
  router: router,
  enableLinkTracking: false,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
});

// @ts-ignore
// eslint-disable-next-line
new Vue({ apolloProvider, i18n, store, router, vuetify, render: (h) => h(App), mixins: [mixins],}).$mount('#app');
