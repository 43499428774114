import {
  BenchmarkData,
  Mittelwerte,
  RootState,
  SnackbarData,
} from '@/store/benchmark/interfaces';

type Averages = Record<
  Partial<keyof Omit<Mittelwerte, '__typename' | '__typename_b'>>,
  number
>;
function calculateAverages(data: Partial<Mittelwerte>[]) {
  const sum = data.reduce((acc: object | Averages, obj) => {
    const {
      bankkategorie,
      bankkategorie_b,
      bezeichnungBankkategorie,
      bezeichnungBankkategorie_b,
      anzahlBanken,
      anzahlBanken_b,
      anzahlProdukte,
      anzahlProdukte_b,
      __typename,
      __typename_b,
      ...rest
    } = obj;
    for (const key in rest) {
      acc[key] = (acc[key] || 0) + rest[key];
    }
    return acc;
  }, {});
  const result = { ...sum };
  Object.keys(sum).forEach((key) => {
    if (key.includes('dispozins') || key.includes('grundpreis')) {
      Object.assign(result, {
        [key]: sum[key] === 0 ? sum[key] : sum[key] / data.length,
      });
    }
  });

  return result;
}
export default {
  loading(state: RootState, payload: boolean) {
    state.loading = payload;
  },
  error(state: RootState, payload: boolean) {
    state.error = payload;
  },
  setBenchmarkData(state: RootState, payload: BenchmarkData[]) {
    const data = { ...payload[0] };
    const averages = calculateAverages(data.mittelwerte) as Averages;

    data.mittelwerteGesamt = {
      ...data.mittelwerteGesamt,
      ...averages,
    };
    //if 2 dates selected
    if (payload.length === 2) {
      payload.forEach((item, index) => {
        if (index === 1) {
          const mapped: Partial<Mittelwerte>[] = item.mittelwerte.map((i) => {
            const o: Partial<Mittelwerte> = {};
            Object.keys(i).forEach((key) => {
              Object.assign(o, { [`${key}_b`]: i[key] });
            });
            return o;
          });
          const updatedMittelwerte = data.mittelwerte.map((it) => {
            const search = mapped.find(
              (i) => i.bankkategorie_b === it.bankkategorie
            );
            return {
              ...it,
              ...search,
            };
          });

          const averagesB = calculateAverages(mapped) as Averages;
          const diff = {};
          const { konto, transaktion } = state.benchmarkFilters;
          [...konto, ...transaktion].forEach((key) => {
            Object.assign(diff, {
              [`diff_${key}`]: averagesB[`${key}_b`] - averages[key],
            });
          });

          data.mittelwerte = updatedMittelwerte.map((item) => {
            const o = { ...item };
            [...konto, ...transaktion].forEach((k) => {
              o[`diff_${k}`] = (o[`${k}_b`] || 0) - o[k];
            });

            return o;
          });
          data.mittelwerteGesamt = {
            ...data.mittelwerteGesamt,
            ...averagesB,
            ...diff,
          };
        }
      });
    }
    state.benchmarkData = data;
    state.loading = false;
  },
  setSnackBarData(state: RootState, payload: SnackbarData) {
    state.snackbarData = payload;
  },
  setFilters(state: RootState, payload: Record<string, string | string[]>) {
    state.benchmarkFilters = { ...state.benchmarkFilters, ...payload };
  },
  setAvailableDates(state: RootState, payload: string[]) {
    state.availableDates = payload;
    state.loadingDates = false;
  },
  setLoadingDates(state: RootState, payload: boolean) {
    state.loadingDates = payload;
  },
  setSelectedDates(state: RootState, payload: Record<'from' | 'to', string>) {
    state.selectedDates = { ...state.selectedDates, ...payload };
  },
};
