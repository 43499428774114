import { CURRENT_YEAR } from '../constants';

const de = {
  general: {
    '10 teuerste anbieter': '10 teuerste Anbieter',
    fmhindexbank: 'FmhIndexBank',
    regionalebank: 'RegionaleBank',
    'top-30-anbieter': 'Top-30-Anbieter',
    banks: 'Banken',
    sollzins: 'Sollzins',
    effektivzins: 'Effektivzins',
    sollzinsbindung: 'Sollzinsbindung',
    darlehen: 'Darlehen',
    kaufpreis: 'Kaufpreis',
    datum: 'Datum',
    top: 'Top',
    all: 'Alle',
    debitInterestRate: 'Sollzinsbindung',
    provider: 'Anbieter',
    totalTable: 'Gesamttabelle',
    anbietername: 'Anbietername',
    neukundenangebot: 'Neukundenangebot',
    zinssatz: 'Zinssatz',
    rendite: 'Rendite',
    Ja: 'Ja',
    ja: 'Ja',
    Nein: 'Nein',
    nein: 'Nein',
    month: 'Monate',
    investmentPeriod: 'Anlagedauer',
    existingCustomersOffers: 'Bestandkunden Angebote',
    onlyNewCustomersOffers: 'Nur Neukunden Angebote',
    allOffers: 'Alle Angebote',
    customerBase: 'Kundenkreis',
    offersFor: 'Angebote für',
    investmentAmount: 'Anlagebetrag',
    minInterestRate: 'Min.Zins',
    maxInterestRate: 'Max.Zins',
    date: 'Datum',
    Notiz: 'Notiz',
    title: 'Titel',
    note: 'Notiz',
    from: 'von',
    to: 'bis',
    with: 'mit',
    possibleOffers: 'möglichen Angeboten',
    institutes: 'Instituten',
    instSelected: 'Instituten gewählt',
    addSubBank: 'Unterbank hinzufügen',
    configureSampleCalculation: 'Musterberechnung konfigurieren',
    years: 'Jahre',
    year: 'Jahre',
    average: 'Mittelwert',
    showChartWith: 'Chart anzeigen mit',
    placeholders: {
      'Bitte wählen...': 'Bitte wählen...',
      pleaseSelect: 'Bitte wählen',
      bitte_wählen: 'Bitte wählen...',
      purchasePrice: 'Kaufpreis',
      credit: 'Darlehen',
      debitInterest: 'Sollzins',
      provider: 'Anbieter',
      effectiveInterestRate: 'Effektivzins',
    },
    actionBtn: {
      refreshTable: 'Tabelle aktualisieren',
      download: 'Herunterladen',
      forWeb: 'Fürs Web',
      forDoc: 'Für Dokumente',
      forDocSmall: 'Für Dokumente (klein)',
      preview: 'Vorschau',
      sendEmail: 'E-Mail senden',
      passwordForgotten: 'Passwort vergessen?',
      logIn: 'Anmelden',
      codeCopied: 'Code kopiert!',
      embedGraphic: 'Grafik einbinden',
      refresh: 'Aktualisieren',
      newPattern: 'Neues Muster',
      selectNone: 'Keine auswählen',
      selectAll: 'Alle auswählen',
      showDataSeries: 'Datenreihe anzeigen',
      hideDataSeries: 'Datenreihe ausblenden',
      logout: 'Abmeldung',
      overview: 'übersicht',
      resetFilters: 'Filter zurücksetzen',
      reset: 'Zurücksetzen',
      showEvaluation: 'auswertung anzeigen',
      save: 'Speichern',
      cancel: 'Abbrechen',
      updateFilter: 'Filter aktualisieren',
      zoom: 'Verkleinern',
      today: 'Heute',
      createReport: 'Report erstellen',
      createEvaluation: 'Auswertung erstellen',
      shortTerm: 'Kurzfristig',
      longTerm: 'Langfristig',
      next: 'Weiter',
      back: 'Zurück',
      apply: 'Übernehmen',
      select: 'Auswählen',
      newProduct: 'neues produkt',
      close: 'Schliessen',
      Hinzufügen: 'Hinzufügen',
      Speichern: 'Speichern',
      delete: 'Löschen',
      edit: 'Ändern',
      copy: 'Kopieren',
    },
    noData: 'Keine Daten vorhanden',
    subHeadlines: {
      buildingMoney: 'Baugeld',
      callMoney: 'Tagesgeld',
      fixedDeposit: 'Festgeld',
      girokonto: 'Girokonto',
    },
    warning: {
      titleRequired: 'Titel ist erforderlich',
      alert: 'Vorsicht',
      notice: 'Hinweis',
    },
    dropDownValues: {
      '5 Jahre': '5 Jahre',
      '10 Jahre': '10 Jahre',
      '15 Jahre': '15 Jahre',
      '20 Jahre': '20 Jahre',
      '25 Jahre': '25 Jahre',
      '30 Jahre': '30 Jahre',
      'Alle Angebote': 'Alle Angebote',
      Privatperson: 'Privatperson',
      Geschäftskunde: 'Geschäftskunde',
    },
    xls: {
      data_from: 'Daten vom',
      trends_of: 'und Trends der letzten zwei Wochen',
    },
  },
  German: 'Deutsch',
  English: 'Englisch',
  header: {
    bankingPortal: 'Der ganze Markt auf einen Blick.',
  },
  subHeader: {
    lastUpdated: 'Letzte Aktualisierung',
  },
  footer: {
    financialConsulting: 'FMH-Finanzberatung e.K.',
    agb: 'AGB',
    anb: 'ANB',
    financialConsultingAddress: 'Zeil 13, D-60313 Frankfurt am Main',
    contact: 'Kontakt',
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    phone: 'Telefon',
    fax: 'Telefax',
  },
  sidebar: {
    menu: {
      dashboard: 'Dashboard',
      interestCharts: 'Zinscharts',
      providerProfiles: 'Anbieterprofile',
      liveData: 'Live-Daten',
      buildingMoney: 'Baugeld',
      callMoney: 'Tagesgeld',
      fixedDeposit: 'Festgeld',
      contest: 'Historische Daten',
      nibc: 'NIBC',
      kfw: 'KfW',
      benchmark: 'Benchmark',
    },
    tooltips: {
      chartGenerator:
        'Zinscharts: Visualisierungen von Zins- und Marktentwicklung\nselbst erstellen und exportieren.',
      bankingProfile:
        'Anbieterprofile: Stammdaten und Bilanzkennzahlen der Player\nauf dem Finanzmarkt abrufen und ' +
        'vergleichen - europaweit.',
      liveData:
        'Live-Daten: Ihre Marktbeobachtung. Zinsen und Konditionen\ndiverser Anbieter abrufen, tracken und vergleichen.',
      contest:
        'Historische Daten: Ihre Marktübersicht. Visualisierte Markt- und Produktentwicklung\nfür Kredite und Anlageprodukte.',
    },
  },
  common: {
    tags: {
      'deutsche Anbieter': 'deutsche Anbieter',
      'Deutsche Anbieter': 'Deutsche Anbieter',
      Vermittler: 'Vermittler',
      'erweiterte Einlagensicherung': 'erweiterte Einlagensicherung',
      'Erweiterte Einlagensicherung': 'Erweiterte Einlagensicherung',
      'flexible Angebote': 'flexible Angebote',
      'Top-Wettbewerber': 'Top-Wettbewerber',
      Neukunden: 'Neukunden',
      Freshmoney: 'Freshmoney',
      'Regionale Anbieter': 'Regionale Anbieter',
      MaRisk: 'MaRisk',
      'Forward-Darlehen': 'Forward-Darlehen',
      'Förderdarlehen (KfW)': 'Förderdarlehen (KfW)',
    },
    dataIterator: {
      noText: 'Erstellen Sie Ihre erste Auswertung',
      groupHeadline: {
        latest: 'Ihre neuesten Auswertungen:',
        autoUpdate: 'Ihre laufenden Markt-Trackings, täglich aktualisiert:',
        other: 'Ihre gespeicherten Markt-Auswertungen',
      },
      cardActions: {
        open: 'Öffnen',
      },
      simpleTable: {
        template: 'Muster',
        products: 'Produkte',
        provider: 'Anbieter',
        runtimes: 'Laufzeiten',
        offers: 'Angebote',
        allOffers: 'Alle Angebote',
        existingCustomersOffers: 'Bestandkunden Angebote',
        onlyNewCustomersOffers: 'Nur Neukunden Angebote',
        selectionCriteria: 'Auswahl-Kriterien',
        businessClient: 'Geschäftskunde',
        privatperson: 'Privatperson',
      },
      footer: {
        page: 'Seite',
        from: 'von',
      },
    },
    datePicker: {
      locale: 'de-DE',
    },
    dateSelection: {
      formatDate: 'TT.MM.JJJJ',
      dataFrom: 'Zinsdaten vom',
      compareWith: 'Vergleichen mit',
      orInterestDataFrom: 'Oder Zinsdaten von',
      hint: 'Um Zinsveränderungen aus einem bestimmten Zeitraum anzuzeigen, nutzen Sie bitte das nebenstehende Icon.',
    },
    noteTagsHint: {
      note: 'Notiz',
      yourNote: 'Ihre Notiz',
      tags: 'Tags',
      hint: 'Hinweis',
      info: {
        note: 'Hier können Sie Ihre Notiz zu dieser Übersicht ansehen, ändern oder neu erstellen.',
        tags: 'Hier können Sie eigene Schlagworte für diese Übersicht anlegen und verwalten.',
      },
      hintDescription:
        'Bei täglich aktualisierten Modellen erfolgt die Datenaktualisierung um 15:00 Uhr.',
      createNote: 'Notiz erstellen',
      createTags: 'Tags erstellen',
    },
    dotsMenu: {
      migrate: 'Migrieren',
      downloadPDF: 'Download PDF',
      downloadXLS: 'Download XLS',
      downloadCSV: 'Download CSV',
      duplicate: 'Duplizieren',
      compare: 'XLS Export alle Nebenbedingungen',
      edit: 'Bearbeiten',
      delete: 'Löschen',
    },
    addNewReportCard: {
      text: 'Neue Auswertung erstellen',
    },
    modalConfirm: {
      titleDelete: 'Möchten Sie fortfahren?',
      reportName: 'Geben Sie Ihrem Bericht einen Namen',
      title: 'Vorsicht!',
      confirm: 'Ja',
      reject: 'Nein',
      save: 'Speichern',
      cancel: 'Abbrechen',
      confirmDeleteReport: 'Ja, löschen',
    },
    subHeader: {
      text: 'Letzte Aktualisierung',
    },
    generalTable: {
      moreThanOneCountry: 'u. D +',
      noOffer: 'Kein Neukundenangebot',
      changesCompared: 'Veränderungen im Vergleich zum',
      comparison: 'Vergleich',
      unlimited: 'unbegrenzt',
      noText: 'Keine Daten vorhanden',
      mediator: 'Vermittler',
      hintCompare: 'Veränderungen im Vergleich zum',
      hintTrend: 'Trend der letzten zwei Wochen',
      interestCreditCallMoney: {
        monthEnd: 'Monatsende',
        quarterEnd: 'Quartalsende',
        yearEnd: 'Jahresende',
        daily: 'Täglich',
        halfYearEnd: 'Halbjahresende',
        startOfMonth: 'Monatsanfang',
      },
      interestCreditFixedDeposit: {
        monthly: 'Monatlich',
        quarterly: 'Vierteljährlich',
        semiannual: 'Halbjährlich',
        yearly: 'Jährlich',
        termEnd: 'Laufzeitende',
      },
      interestCalculation: {
        germanMethod: 'Deutsche Methode',
        euroInterestMethod: 'Eurozins Methode',
        englishMethod: 'Englische Methode',
        effectiveInterestMethod: 'Effektivzins Methode',
      },
      headers: {
        zielgruppe: 'Zielgruppe',
        customerBase: 'Kundenkreis',
        produkt_maximalanlageProdukt: 'Max Anlage',
        produkt_neukundenangebot: 'Zinsänderung',
        produkt_abBetrag: 'Zins gilt ab €',
        produkt_anschlussZinssatz: 'Anschlusszins',
        produkt_zinssatz_difference: 'Differenz',
        produkt_zinssatzVeraenderung: 'Differenz',
        produkt_aktionszinssatzVeraenderung: 'Differenz',
        '1M_zinssatz_difference': 'Differenz',
        '2M_zinssatz_difference': 'Differenz',
        '3M_zinssatz_difference': 'Differenz',
        '6M_zinssatz_difference': 'Differenz',
        '9M_zinssatz_difference': 'Differenz',
        '12M_zinssatz_difference': 'Differenz',
        '18M_zinssatz_difference': 'Differenz',
        '24M_zinssatz_difference': 'Differenz',
        '36M_zinssatz_difference': 'Differenz',
        '48M_zinssatz_difference': 'Differenz',
        '60M_zinssatz_difference': 'Differenz',
        '72M_zinssatz_difference': 'Differenz',
        '84M_zinssatz_difference': 'Differenz',
        '96M_zinssatz_difference': 'Differenz',
        '108M_zinssatz_difference': 'Differenz',
        '120M_zinssatz_difference': 'Differenz',
        '1M_zinssatzVeraenderung': 'Differenz',
        '2M_zinssatzVeraenderung': 'Differenz',
        '3M_zinssatzVeraenderung': 'Differenz',
        '6M_zinssatzVeraenderung': 'Differenz',
        '9M_zinssatzVeraenderung': 'Differenz',
        '12M_zinssatzVeraenderung': 'Differenz',
        '18M_zinssatzVeraenderung': 'Differenz',
        '24M_zinssatzVeraenderung': 'Differenz',
        '36M_zinssatzVeraenderung': 'Differenz',
        '48M_zinssatzVeraenderung': 'Differenz',
        '60M_zinssatzVeraenderung': 'Differenz',
        '72M_zinssatzVeraenderung': 'Differenz',
        '84M_zinssatzVeraenderung': 'Differenz',
        '96M_zinssatzVeraenderung': 'Differenz',
        '108M_zinssatzVeraenderung': 'Differenz',
        '120M_zinssatzVeraenderung': 'Differenz',
        anbieter: 'Anbieter',
        produkt: 'Produkt',
        '1M_zinssatz': '1 Mon.',
        '3M_zinssatz': '3 Mon.',
        '6M_zinssatz': '6 Mon.',
        '9M_zinssatz': '9 Mon.',
        '12M_zinssatz': '12 Mon.',
        '18M_zinssatz': '18 Mon.',
        '24M_zinssatz': '24 Mon.',
        '36M_zinssatz': '36 Mon.',
        '48M_zinssatz': '48 Mon.',
        '60M_zinssatz': '60 Mon.',
        '72M_zinssatz': '72 Mon.',
        '84M_zinssatz': '84 Mon.',
        '96M_zinssatz': '96 Mon.',
        '108M_zinssatz': '108 Mon.',
        '120M_zinssatz': '120 Mon.',
        produkt_datumZinsaenderung: 'Zinsänderung',
        produkt_mindestanlage: 'Min Anlage',
        produkt_maximalanlage: 'Max Anlage',
        produkt_zinsgutschrift: 'Gutschrift',
        produkt_zinsberechnung: 'Berechnung',
        einlagensicherung: 'Einlagensicherung',
        produkt_zinssatz: 'Zinssatz',
        produkt_aktionszinssatz: 'Aktionszins',
        aktionszins: 'Aktionszins',
        produkt_zinsgarantie: 'Zinsgarantie',
        '2M_zinssatz': '2 Mon.',
      },
      hints: {
        extended_deposit_insurance: 'Erweiterte Einlagensicherung',
      },
    },
  },
  login: {
    titles: {
      resetPassword: 'Passwort zurücksetzen',
    },
    errors: {
      dataNotCorrect:
        'Nutzerdaten nicht korrekt. Bitte versuchen Sie es erneut.',
      apiError: 'Bei der API Anfrage trat ein Fehler auf.',
    },
    placeholders: {
      enterYourEmail:
        'Bitte geben Sie die E-Mail-Adresse Ihres Kontos ein und wir\n' +
        'senden Ihnen einen Link zum Zurücksetzen des Passworts',
      password: 'Passwort',
      email: 'E-mail',
    },
    rules: {
      emptyEmail: 'E-mail muss ausgefüllt sein',
      emptyPassword: 'Passwort muss ausgefüllt sein',
      wrongEmailFormat: 'E-mail hat ein falsches Format',
    },
    messages: {
      checkYourEmail: 'Bitte überprüfen Sie Ihre E-Mail',
    },
  },
  dashboard: {
    topAnbieter: {
      tagesgeldTop5: 'Tagesgeld Top 5',
      festgeldTop5: 'Festgeld Top 5',
      rang: 'Rang',
      zins: 'Zins',
      competitor: 'Wettbewerber',
      '2y': '2j',
      '5y': '5j',
      '10y': '10j',
      '3m': '3m',
      '6m': '6m',
      '12m': '12m',
      noData: 'Keine Daten vorhanden',
    },
    headerTitle: 'Der ganze Markt auf einen Blick.',
    firstWelcome: 'Willkommen im Dashboard Ihres FMH X Radar',
    welcome: 'Gutten Tag',
    newHere:
      'Nutzen Sie unsere innovative SaaS-Lösung und gestalten Sie Ihre Marktbeobachtung effizienter' +
      ' und einfacher. Verschaffen Sie sich einen umfassenden Überblick über die neuesten Trends und Entwicklungen, ' +
      'damit Sie stets bestens informiert sind und schnell auf Veränderungen reagieren können.',
    toVideoGuide: 'Zum Video-Ratgeber',
    everythingClear:
      'Schon alles klar? Mit welcher Auswertung möchten Sie starten?',
    marketSurveillance: 'Marktbeobachtung',
    createEvaluation:
      'Erstellen Sie Auswertung über \n die Konditionen der Mitbewerber',
    selectLabel: 'Produkt wählen...',
    competitiveComparison: 'Wettbewerbsvergleich',
    visualizeDevelopment:
      'Visualisieren Sie die Entwicklung \n von Kredit- und Anlagenprodukte',
    contest: 'Wettbewerbs',
    comparison: 'vergleich',
    interest: 'Zins',
    interestRateCharts: 'Zins-Charts',
    compareFinancial: 'Vergleichen Sie Finanzprodukte \n und Indizes',
    newChart: 'neues Chart',
    yourLatestEvaluations: 'Ihre neuesten Auswertungen',
    whatToDoNext: 'Was möchten Sie als nächstes tun?',
    createInterestRateChart: 'Zins-Chart erstellen',
    trackConstructionMoney: 'Baugeld tracken',
    createCallMoneyComparison: 'Tagesgeld-Vergleich erstellen',
  },
  benchmark: {
    filters: {
      legend: 'Benchmark erstellen',
      nachBankTypeLabel: 'Nach Banktyp oder Bundesland filtern',
      zeitPunkteLabel: 'Zeitpunkte vergleichen',
      bankTypeLabel: 'Nach Kreditinstitutstyp filtern',
      kontoLabel: 'Preisgestaltung Konto und Karte',
      transaktionenLabel: 'Gebühren für Transaktionen',
      actionButton: 'Daten laden',
      errorMessage: 'Die Daten müssen nicht übereinstimmen',
      errorMessage2: 'Bitte ein Datum wählen',
      labelInOptions: {
        sparkassen: 'Sparkassen',
        genossenschaftsbanken: 'Genossenschaftsbanken',
        direktbanken: 'Direktbanken',
        vermittlerbanken: 'Vermittlerbanken',
        bundesweiteBanken: 'Bundesweite Banken',
        regionaleBanken: 'Regionale Banken',
        geschaftsbanken: 'Geschäftsbanken',
        monatlicherGrundpreis: 'Monatlicher Grundpreis',
        dispozins: 'Dispozins',
        jahresgebuhrFurBankKarte: 'Jahresgebühr für Bankkarte',
        jahresgebuhrKreditkarte: 'Jahresgebühr Kreditkarte',
        gutschrift: 'Gutschrift',
        lastschrift: 'Lastschrift',
        beleghafteUberweisung: 'Beleghafte Überweisung',
        belegloseUberweisung: 'Beleglose Überweisung',
        baden: 'Baden-Württemberg',
        bayern: 'Bayern',
        berlin: 'Berlin',
        brandenburg: 'Brandenburg',
        bremen: 'Bremen',
        hamburg: 'Hamburg',
        hessen: 'Hessen',
        mecklenburg: 'Mecklenburg-Vorpommern',
        niedersachsen: 'Niedersachsen',
        nordrhein: 'Nordrhein-Westfalen',
        rheinland: 'Rheinland-Pfalz',
        saarland: 'Saarland',
        sachsen: 'Sachsen',
        sachsenAnhalt: 'Sachsen-Anhalt',
        schleswigHolstein: 'Schleswig-Holstein',
        thuringen: 'Thüringen',
      },
    },
    title: 'Benchmark',
    explanations:
      'Sehen Sie die Mittelwerte der wichtigsten Kosten und Gebühren von Girokonten im Markt auf einen Blick. Filtern ' +
      'Sie nach Region oder Banktyp, um genau die Informationen zu erhalten, die für Ihre Analysen relevant sind.',
    table: {
      title: 'Ergebnis',
      totalResult: 'Gesamtergebnis',
      headers: {
        designationBankCategory: 'Bundesland',
        numberBanks: 'Anzahl der Banken',
        numberProducts: 'Anzahl der Girokonten',
        basePrice: 'Mittelwert Grundpreis',
        discountInterest: 'Mittelwert Dispozins',
        diff: 'Differenz',
        jahresgebuhrFurBankKarte: 'Jahresgebühr für Bankkarte',
        jahresgebuhrKreditkarte: 'Jahresgebühr Kreditkarte',
        gutschrift: 'Gutschrift',
        lastschrift: 'Lastschrift',
        beleghafteUberweisung: 'Beleghafte Überweisung',
        belegloseUberweisung: 'Beleglose Überweisung',
      },
    },
  },
  liveData: {
    generalTable: {
      noOfferTagesgeld: 'Kein Tagesgeld-Angebot',
      noOfferFestgeld: 'Kein Festgeld-Angebot',
    },
    title: 'Live-Daten',
    explanations:
      'Hier sehen Sie Ihre zuletzt verwendeten Auswertungen in\n' +
      'chronologischer Reihenfolge. Sie können neue Auswertungen\n' +
      'erstellen, oder bereits vorhandene anpassen, exportieren und\n' +
      'duplizieren. Recherchieren Sie tagesaktuelle Zinsen und\n' +
      'Konditionen, speichern Sie wichtige Momentaufnahmen oder richten\n' +
      'Sie ein regelmäßiges Tracking der Produkte Ihrer wichtigsten\n' +
      'Mitbewerber ein.',
  },
  singleReport: {
    title: 'Auswertung personalisieren',
    dateSelection: {
      label:
        'Sie haben die Möglichkeit, Konditionen anhand von zwei Zeitpunkten zu vergleichen. \n Beginnen Sie damit, ' +
        'das Datum auszuwählen, das weiter in der Vergangenheit liegt.',
      button: 'Live-Daten laden',
      compareButton: 'Zeitpunkte vergleichen',
    },
  },
  chartGenerator: {
    showYAxis: 'Vertikale Linien',
    minMax: 'Min. und Max. Werte einblenden',
    timePeriod: 'Zeitraum auswählen',
    selectProduct: 'Produkt auswählen',
    selectType: 'Typ wählen',
    explanations:
      'Mit den Zinscharts visualisieren Sie Zinsverläufe und Marktentwicklungen nach Ihrem Bedarf und können die ' +
      'erstellte Grafik als Bilddatei herunterladen oder per iFrame einbinden.',
    criteria: 'Kriterien',
    title: 'Zinscharts',
    subHeadline: 'Grafiken erstellen und exportieren',
    explanation:
      'Hier können Sie Zinsverläufe und Marktentwicklungen selbst erstellen. Bitte beachten Sie: Die Exportfunktion wird bald zur Verfügung stehen',
    contact: 'Kontaktieren Sie uns gern',
    wantExport:
      'wenn Sie eine Grafik bzw. die zugehörigen Daten exportieren oder per  API in Ihre eigenen BI- und Webanwendungen ziehen möchten',
    tab1Text: 'Basis-Grafiken',
    tab2Text: 'Spezial-grafiken',
    step: 'Schritt',
    series: 'Kennzahl',
    subtitle: 'Bis zu 3 Datenreihen auswählen',
    setPeriod: 'Zeitraum festlegen',
    specialFeatures: 'Besonderheiten',
    rules: {
      dataEntryRequired: 'Eingabe erforderlich!',
    },
    createGraphic: 'Grafik erstellen',
    embedGraphic: 'Grafik einbinden',
    chartTypes: {
      lineChart: 'Liniendiagramm',
      areaChart: 'Flächendiagramm',
    },
    differential: 'Differenzwert',
    annotations: 'Annotationen',
    fmhLogo: 'FMH X Logo einblenden',
    logo: 'Logo',
    sourcePosition: {
      label: 'Position Quellenangabe',
      options: {
        topLeft: 'Oben links',
        topRight: 'Oben rechts',
        bottomLeft: 'Unten links',
        bottomRight: 'Unten rechts',
      },
    },
    hints: {
      sourcePosition:
        'Wählen Sie die Platzierung der Quellenangabe sowie des Zeitraums in der Grafik.',
      showMinMax: 'Sie können die Anzeige der Min. und Max. Werte ausblenden.',
      split:
        'Liegen zwei Kurven sehr weit auseinander - wie Tagesgeld und Giro Dispo -, können Sie die Skalieung ' +
        'unterbrechen, \n um detailliertere Zinsverläufe in feineren Indexabstufungen darzustellen.',
      differentialValue:
        'Visualisieren Sie den Differenzwert aus zwei Kurven: Der Wert der unteren Datenreihe wird von der oberen abgezogen.',
      annotation:
        'Weltpolitische Entscheidungen, wirtschaftliche Ereignisse, große Naturkatastrophen:\n' +
        ' Sehen Sie, welche Geschehnisse Auswirkung auf die Zinsen hatten.',
      showLogo: 'Sie können die Anzeige des Logos in der Grafik ausblenden.',
    },
    interestOptions: {
      tagesgeld: 'Tagesgeld',
      festgeld: 'Festgeld',
      sparbuch: 'Sparbuch',
      hypothekenzinsen: 'Bauzinsen',
      ratenkredit: 'Ratenkredit',
      giro: 'Giro',
      euribor: 'Euribor',
      eonia: 'EONIA / ESTER',
      ezb_emp: 'EZB',
      inflation: 'Inflation',
      umlaufrendite: 'Umlaufrendite',
      ba_10: 'Bundesanleihe 10 Jahre',
      pfandbriefe: 'Pfandbriefe (lt. Bundesbank)',
      basiszins_bgb: 'Basiszins §247 BGB',
    },
    subInterestOptions: {
      ezb_emp: {
        ezb_einlagenzins: 'Einlagenzins',
        ezb: 'ReFi-Satz',
      },
      pfandbriefe: {
        pb_1: '1 Jahr',
        pb_5: '5 Jahre',
        pb_10: '10 Jahre',
      },
      tagesgeld: {
        tg_basis: 'ab 1 €',
        tg_5000: '5.000 €',
        tg_50000: '50.000 €',
        tg_bb: 'laut Bundesbank',
      },
      festgeld: {
        fg_5000_3: '3 Monate',
        fg_5000_6: '6 Monate',
        fg_5000_12: '12 Monate',
        fg_50000_3: '3 Monate',
        fg_50000_6: '6 Monate',
        fg_50000_12: '12 Monate',
        sb_2: '2 Jahre',
        sb_3: '3 Jahre',
        sb_4: '4 Jahre',
        sb_5: '5 Jahre',
        sb_7: '7 Jahre',
        sb_10: '10 Jahre',
      },
      sparbuch: {
        spareckzins: 'Basis- / Spareckzins',
        spareinlage_50000: '50.000 €',
      },
      hypothekenzinsen: {
        bg_5: '5 Jahre',
        bg_10: '10 Jahre',
        bg_15: '15 Jahre',
        bg_20: '20 Jahre',
        kfw_124: 'Kfw 124',
      },
      ratenkredit: {
        rk_36: '36 Monate',
        rk_48: '48 Monate',
        rk_60: '60 Monate',
        rk_72: '72 Monate',
      },
      giro: {
        dispo: 'Dispo',
        dispo_plus: 'Weitere Überziehung',
      },
      euribor: {
        euribor_1: '1 Monate',
        euribor_3: '3 Monate',
        euribor_6: '6 Monate',
      },
    },
    specialChartOptions: {
      rbGrafikAuswahl1: 'Hypothekenzinsen ab 1980',
      rbGrafikAuswahl2: 'Bausparzinsen ab 1993',
      rbGrafikAuswahl3: 'Forward-Aufschläge ab 2010',
      goldpreis: 'Goldpreis laut Bundesbank',
      rbGrafikAuswahl5: 'Hauspreis-Entwicklung ab 2004',
    },
    specialChartSubOptions: {
      rbGrafikAuswahl1: {
        effektivzins_5: 'Zinsbindung 5 Jahre',
        effektivzins_10: 'Zinsbindung 10 Jahre',
        effektivzins_15: 'Zinsbindung 15 Jahre',
      },
      rbGrafikAuswahl2: {
        bs_guthabenszins: 'BSV-Guthabenzins',
        bs_tagesgeld_index: 'Tagesgeldzinsen',
        bs_darlehenszins: 'BSV-Darlehenzins',
        bs_baugeld_10: 'Hypothekenzins 10 Jahre',
      },
      rbGrafikAuswahl3: {
        fd_aufschlag_12: 'Aufschlag für 12 Monate',
        fd_aufschlag_24: 'Aufschlag für 24 Monate',
        fd_aufschlag_36: 'Aufschlag für 36 Monate',
        fd_effektivzins_12:
          'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 12 Mon.',
        fd_effektivzins_24:
          'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 24 Mon.',
        fd_effektivzins_36:
          'Eff.Zins 10 J. fest inkl. \nForward-Aufschlag für 36 Mon.',
      },
      rbGrafikAuswahl5: {
        hp_darlehenhoehe: 'Bauzinsen und Kredithöhe',
        hp_hypothekenzins_index:
          'Bauzinsen 10 Jahre fest Quartalsmittelwert in %',
        hp_einkommen: 'Nettoeinkommen und Kredithöhe',
        hp_einkommen_hp_darlehenhoehe:
          'Bauzinsen 10 Jahre fest Quartalsmittelwert in %',
        hp_preis_neubau_etw: 'Kredithöhe und Immobilienpreise',
        hp_preis_neubau_etw_hp_darlehenhoehe: 'Mögliche Kredithöhe in Euro',
        hp_preis_neubau_etw_hp_preis_neubau_haus:
          'Durchschnittliche Neubau-Hauspreise in Euro',
        hp_preis_neubau_haus: 'Hauspreis und Nettoeinkommen',
        hp_preis_neubau_haus_hp_einkommen:
          'Nettoeinkommen p. M. für 2 Personen',
      },
    },
    average: 'Durchschnitt',
    difference: 'Differenz',
    and: 'und',
    error: 'Bei der API Anfrage trat ein Fehler auf',
    modal: {
      title: 'Code kopieren und in den Quellcode Ihrer Website einfügen',
      codeCopied: 'Code kopiert!',
    },
    colors: 'Farben',
    color: 'Farbe',
    specialChartHousePriceDescription: {
      hp_hypothekenzins_index_hp_darlehenhoehe:
        'Die verwendeten Zinsen (80% Finanzierung) sind die Durchschnittszinsen pro' +
        ' Quartal (Zinsbindung 10 Jahre fest) aus der FMH-Datenbank. Die errechnete Kredithöhe unterstellt eine' +
        ' Finanzierungsdauer von 30 Jahren bei gleichbleibendem Zinssatz.',
      hp_darlehenhoehe_hp_einkommen:
        'Das verwendete Nettoeinkommen basiert auf den Zahlen des Statistischen Bundesamtes ' +
        'vom Febr. 2023. Die verwendeten Zinsen (80% Finanzierung) sind die Durchschnittszinsen pro Quartal (Zinsbindung ' +
        '10 Jahre fest) aus der FMH-Datenbank. Die errechnete Kredithöhe unterstellt eine Finanzierungsdauer von 30 Jahren' +
        ' bei gleichbleibendem Zinssatz.',
      hp_darlehenhoehe_hp_preis_neubau_haus_hp_preis_neubau_etw:
        'Die errechnete Kredithöhe unterstellt eine' +
        ' Finanzierungsdauer von 30 Jahren bei gleichbleibendem Zinssatz. Das zugrunde gelegte Nettoeinkommen basiert' +
        ` auf den Zahlen des Statistischen Bundesamtes vom Febr. ${CURRENT_YEAR} für einen Doppelverdienerhaushalt. Die verwendeten` +
        ' Zinsen (80% Finanzierung) sind die Durchschnittszinsen pro Quartal (Zinsbindung 10 Jahre fest) aus der ' +
        'FMH-Datenbank. Die Haus- und ETW-Preise (140 und 100 qm) beruhen auf den Zahlen von „empirica“ vom Dezember ' +
        `${
          CURRENT_YEAR - 1
        }  und sind jeweils Quartalsmittelwerte für Neubauten im ganzen Bundesgebiet.`,
      hp_einkommen_hp_preis_neubau_haus:
        'Das zugrunde gelegte Nettoeinkommen basiert auf den Zahlen des Statistischen ' +
        'Bundesamtes vom Febr. 2023 für einen Doppelverdienerhaushalt. Die Haus-Preise für 140 qm Wohnfläche beruhen auf ' +
        'den Zahlen von „empirica“ vom Dezember 2022 und sind jeweils Quartalsmittelwerte für Neubauten im ganzen' +
        ' Bundesgebiet.',
    },
    cartLabels: {
      hp_hypothekenzins_index:
        'Bauzinsen 10 Jahre fest Quartalsmittelwert in %',
      hp_darlehenhoehe: 'Mögliche Kredithöhe in Euro',
      hp_einkommen: 'Nettoeinkommen p. M. für 2 Personen in Euro',
      hp_preis_neubau_haus: 'Durchschnittliche Neubau-Hauspreise in Euro',
      hp_preis_neubau_etw: 'Durchschnittliche Neubau-ETW-Preise in Euro',
    },
  },
  bankingProfile: {
    more: 'weitere',
    title: 'Neue Anbieter-Auswertung anlegen',
    headline: 'Anbieterprofile',
    explanationText: 'Bitte geben Sie den Titel und den Notiz des Berichts ein',
    selectColumns: 'spalten auswählen',
    closeColumnSelect: 'spaltenauswählen schliessen',
    update: 'aktualisieren',
    selectAll: 'Alle',
    timePeriod: 'Zeitraum auswählen',
    region: 'Region auswählen',
    keyAreas: 'Schwerpunkte',
    activity: 'Aktivität',
    dataFilter: 'Daten filtern',
    headerTitle: 'Anbieterprofile',
    subHeadline: 'Ihre neuesten Übersichten',
    subHeadlineNewReportPage: 'Neue Anbieter-Übersicht anlegen',
    explanation:
      'Hier sehen Sie Ihre zuletzt verwendeten Übersichten in chronologischer Reihenfolge. \nSie können neue Übersichten erstellen, oder bereits vorhandene anpassen, exportieren und duplizieren.',
    world: 'welt',
    regionOptions: {
      dach: 'dach',
      europa: 'europa',
      eu: 'eu',
      benelux: 'benelux',
      brics: 'brics',
      afrika: 'afrika',
      asien: 'asien',
      'australien und ozeanien': 'australien und ozeanien',
      amerikas: 'amerikas',
    },
    countries: {
      Schweiz: 'Schweiz',
      Deutschland: 'Deutschland',
      Frankreich: 'Frankreich',
      Italien: 'Italien',
      Luxemburg: 'Luxemburg',
      Niederlande: 'Niederlande',
      Dänemark: 'Dänemark',
      Irland: 'Irland',
      Griechenland: 'Griechenland',
      Portugal: 'Portugal',
      Spanien: 'Spanien',
      Finnland: 'Finnland',
      Österreich: 'Österreich',
      Schweden: 'Schweden',
      Estland: 'Estland',
      Lettland: 'Lettland',
      Litauen: 'Litauen',
      Malta: 'Malta',
      Polen: 'Polen',
      Slowakei: 'Slowakei',
      Slowenien: 'Slowenien',
      Tschechien: 'Tschechien',
      Ungarn: 'Ungarn',
      Zypern: 'Zypern',
      Bulgarien: 'Bulgarien',
      Rumänien: 'Rumänien',
      Kroatien: 'Kroatien',
      Albanien: 'Albanien',
      Andorra: 'Andorra',
      Belarus: 'Belarus',
      Belgien: 'Belgien',
      'Bosnien und Herzegowina': 'Bosnien und Herzegowina',
      Island: 'Island',
      Kasachstan: 'Kasachstan',
      Kosovo: 'Kosovo',
      Liechtenstein: 'Liechtenstein',
      Moldau: 'Moldau',
      Monaco: 'Monaco',
      Montenegro: 'Montenegro',
      Nordmazedonien: 'Nordmazedonien',
      Norwegen: 'Norwegen',
      Russland: 'Russland',
      'San Marino': 'San Marino',
      Serbien: 'Serbien',
      Türkei: 'Türkei',
      Ukraine: 'Ukraine',
      Vatikanstadt: 'Vatikanstadt',
      'Vereinigtes Königreich': 'Vereinigtes Königreich',
      Indien: 'Indien',
      China: 'China',
      Südafrika: 'Südafrika',
      Ägypten: 'Ägypten',
      Algerien: 'Algerien',
      Angola: 'Angola',
      Äquatorialguinea: 'Äquatorialguinea',
      Äthiopien: 'Äthiopien',
      Benin: 'Benin',
      Botswana: 'Botswana',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      Dschibuti: 'Dschibuti',
      Elfenbeinküste: 'Elfenbeinküste',
      Eritrea: 'Eritrea',
      Eswatini: 'Eswatini',
      Gabun: 'Gabun',
      Gambia: 'Gambia',
      Ghana: 'Ghana',
      Guinea: 'Guinea',
      'Guinea-Bissau': 'Guinea-Bissau',
      Kamerun: 'Kamerun',
      'Kap Verde': 'Kap Verde',
      Kenia: 'Kenia',
      Komoren: 'Komoren',
      'Kongo, Demokratische Republik': 'Kongo, Demokratische Republik',
      'Kongo, Republik': 'Kongo, Republik',
      Lesotho: 'Lesotho',
      Liberia: 'Liberia',
      Libyen: 'Libyen',
      Madagaskar: 'Madagaskar',
      Malawi: 'Malawi',
      Mali: 'Mali',
      Marokko: 'Marokko',
      Mauretanien: 'Mauretanien',
      Mauritius: 'Mauritius',
      Mosambik: 'Mosambik',
      Namibia: 'Namibia',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Ruanda: 'Ruanda',
      Sambia: 'Sambia',
      'São Tomé und Príncipe': 'São Tomé und Príncipe',
      Senegal: 'Senegal',
      Seychellen: 'Seychellen',
      'Sierra Leone': 'Sierra Leone',
      Simbabwe: 'Simbabwe',
      Somalia: 'Somalia',
      Sudan: 'Sudan',
      Südsudan: 'Südsudan',
      Tansania: 'Tansania',
      Togo: 'Togo',
      Tschad: 'Tschad',
      Tunesien: 'Tunesien',
      Uganda: 'Uganda',
      'Zentralafrikanische Republik': 'Zentralafrikanische Republik',
      Afghanistan: 'Afghanistan',
      Armenien: 'Armenien',
      Aserbaidschan: 'Aserbaidschan',
      Bahrain: 'Bahrain',
      Bangladesch: 'Bangladesch',
      Bhutan: 'Bhutan',
      Brunei: 'Brunei',
      'Volksrepublik China': 'Volksrepublik China',
      Georgien: 'Georgien',
      Indonesien: 'Indonesien',
      Irak: 'Irak',
      Iran: 'Iran',
      Israel: 'Israel',
      Japan: 'Japan',
      Jemen: 'Jemen',
      Jordanien: 'Jordanien',
      Kambodscha: 'Kambodscha',
      Katar: 'Katar',
      Kirgisistan: 'Kirgisistan',
      Nordkorea: 'Nordkorea',
      Südkorea: 'Südkorea',
      Kuwait: 'Kuwait',
      Laos: 'Laos',
      Libanon: 'Libanon',
      Malaysia: 'Malaysia',
      Malediven: 'Malediven',
      Mongolei: 'Mongolei',
      Myanmar: 'Myanmar',
      Nepal: 'Nepal',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Philippinen: 'Philippinen',
      'Saudi-Arabien': 'Saudi-Arabien',
      Singapur: 'Singapur',
      'Sri Lanka': 'Sri Lanka',
      Syrien: 'Syrien',
      Tadschikistan: 'Tadschikistan',
      Thailand: 'Thailand',
      Turkmenistan: 'Turkmenistan',
      Usbekistan: 'Usbekistan',
      'Vereinigte Arabische Emirate': 'Vereinigte Arabische Emirate',
      Vietnam: 'Vietnam',
      Australien: 'Australien',
      Cookinseln: 'Cookinseln',
      Fidschi: 'Fidschi',
      Kiribati: 'Kiribati',
      Marshallinseln: 'Marshallinseln',
      Mikronesien: 'Mikronesien',
      Nauru: 'Nauru',
      Neuseeland: 'Neuseeland',
      Palau: 'Palau',
      Salomonen: 'Salomonen',
      Samoa: 'Samoa',
      Tonga: 'Tonga',
      Tuvalu: 'Tuvalu',
      Vanuatu: 'Vanuatu',
      'Amerikanisch-Samoa': 'Amerikanisch-Samoa',
      Bougainville: 'Bougainville',
      'Französisch-Polynesien': 'Französisch-Polynesien',
      Guam: 'Guam',
      Neukaledonien: 'Neukaledonien',
      Niue: 'Niue',
      'Nördliche Marianen': 'Nördliche Marianen',
      Norfolkinsel: 'Norfolkinsel',
      Pitcairninseln: 'Pitcairninseln',
      Tokelau: 'Tokelau',
      'Wallis und Futuna': 'Wallis und Futuna',
      'United States Minor Outlying Islands':
        'United States Minor Outlying Islands',
      'Antigua und Barbuda': 'Antigua und Barbuda',
      Argentinien: 'Argentinien',
      Bahamas: 'Bahamas',
      Barbados: 'Barbados',
      Belize: 'Belize',
      Bolivien: 'Bolivien',
      Brasilien: 'Brasilien',
      Chile: 'Chile',
      'Costa Rica': 'Costa Rica',
      Dominica: 'Dominica',
      'Dominikanische Republik': 'Dominikanische Republik',
      'El Salvador': 'El Salvador',
      Ecuador: 'Ecuador',
      Grenada: 'Grenada',
      Guatemala: 'Guatemala',
      Guyana: 'Guyana',
      Haiti: 'Haiti',
      Honduras: 'Honduras',
      Jamaika: 'Jamaika',
      Kanada: 'Kanada',
      Kolumbien: 'Kolumbien',
      Kuba: 'Kuba',
      Mexiko: 'Mexiko',
      Nicaragua: 'Nicaragua',
      Panama: 'Panama',
      Paraguay: 'Paraguay',
      Peru: 'Peru',
      'St. Kitts und Nevis': 'St. Kitts und Nevis',
      'St. Lucia': 'St. Lucia',
      'St. Vincent und die Grenadinen': 'St. Vincent und die Grenadinen',
      Suriname: 'Suriname',
      'Trinidad und Tobago': 'Trinidad und Tobago',
      Uruguay: 'Uruguay',
      Venezuela: 'Venezuela',
      USA: 'USA',
    },
    filter: {
      fields: 'Auswahl',
      explanationText:
        'Bitte geben Sie den Titel und den Notiz des Berichts ein',
      update: 'aktualisieren',
      filter: 'Filter',
      dataFilter: 'Daten filtern',
      selectAll: 'Alle',
      timePeriod: 'Zeitraum',
      region: 'Region',
      activity: 'Aktivität',
      selectColumns: 'spalten auswählen',
      closeColumnSelect: 'spaltenauswählen schliessen',
      listedOnStockExchange: 'An der Börse notiert',
      timePeriodTooltip:
        'Wählen Sie den Zeitraum, für den Sie Bilanzkennzahlen anzeigen möchten.' +
        ' \n Sie können dabei auch mehrere Jahre auswählen. ' +
        '\n Klicken Sie anschließend auf „Aktualisieren“, um die Daten anzuzeigen.',
      regionTooltip:
        'Sie können per Klick komplette Regionen auswählen, über ' +
        '“Land suchen” einzelne Länder auswählen - oder auch beide ' +
        'Optionen kombinieren.\n' +
        'Wenn Sie direkt in das Eingabefeld tippen, können Sie ' +
        'gewünschte Ländern noch schneller finden.',
    },
    description: 'Kurzprofil',
    table: {
      loadingText: 'Laden von items...',
      saveXLS: 'XLS Download',
      explanationText: 'Laden Sie die Ansicht als Excel Datei',
      selectedAdditionalHeadersLabel: 'Spalten einblenden',
      filter: {
        comparison: 'Vergleich',
        value: 'Wert',
        select: 'Wählen',
        booleanText: 'Ja/Nein',
        fields: 'Auswahl',
        regionLabel: 'Land suchen',
        larger: 'Größer',
        smaller: 'Kleiner',
        equal: 'Gleich',
        regionSelection: 'Land wählen',
      },
      headers: {
        provider: 'Anbieter',
        locations: 'Standorte',
        keyAreas: 'Geschäftsfelder',
        totalCapitalRatio: 'Gesamtkapitalquote',
        returnOnEquity: 'Eigenkapitalrendite',
        bankRatings: 'Ratings',
        headOffice: 'Hauptsitz',
        depositInsurance: 'Einlagensicherung',
        totalCapitalRatioCountry: 'Gesamtkapitalquote \n Land',
        totalCapitalRatioAverage: 'Gesamtkapitalquote \n durchschnittlich',
        totalAssets: 'Bilanzsumme',
        foundationLocation: 'Gründungsort',
        shareholder: 'Gesellschafter',
        subsidiaries: 'Tochtergesellschaften',
        stockExchangeListed: 'Börsennotiert',
        employees: 'Mitarbeiter',
        branchOffices: 'Filialen',
        coreStory: 'Kerngeschäft',
        mortgageVolume: 'Hypothekenvolumen',
        coreCapitalRatio: 'Kernkapitalquote',
        marketCapitalization: 'Marktkapitalisierung',
        equity: 'Eigenkapital',
        leverageRatio: 'Leverage Ratio',
        ratingParentCompany: 'Rating \n Muttergesellschaft',
        date: 'Datum',
        foundationYear: 'Gründungsjahr',
        returnEquityAverage: 'Durchschnittliche Eigenkapitalrendite',
        creditVolume: 'Kreditvolumen',
        investmentVolume: 'Anlagevolumen',
        commissionIncome: 'Provisionseinnahmen',
        profit: 'Gewinn',
        savingsDeposits: 'Spareinlagen',
        otherliabilities: 'Andere Verbindlichkeiten',
        totalPayables: 'Summe der Verbindlichkeiten',
        lienOnRealProperty: 'Grundpfandrecht',
        receivablesFromBanks: 'Forderungen an Kreditinstitute',
        receivablesCustomers: 'Forderungen an Kunden',
        commissionExpenses: 'Provisionsaufwendungen',
        netCommissionIncome: 'Provisionsertrag',
        commissionSurplus: 'Provisionsüberschuss',
      },
    },
    demoUserAlert:
      'In der Demoversion werden nur 10 Anbieter/Provider von 279 angezeigt.',
  },
  contest: {
    subHeadlines: {
      buildingMoney: 'Neue Baugeld-Auswertung: Zinsentwicklung im Vergleich',
      callMoney: 'Neue Tagesgeld-Auswertung: Zinsentwicklung im Vergleich',
      fixedDeposit: 'Neue Festgeld-Auswertung: Zinsentwicklung im Vergleich',
    },
    explanations: {
      allReportsPage:
        'Hier sehen Sie Ihre zuletzt verwendeten Grafiken in chronologischer Reihenfolge. Sie können neue ' +
        'Visualisierungen erstellen, oder bereits vorhandene anpassen, exportieren oder duplizieren. Vergleichen Sie ' +
        'die Konditionsentwicklung von bis zu fünf Anbieter/Provider und sehen Sie, wie Ihr Wettbewerb in den letzten ' +
        'Wochen, Monaten oder Jahren auf dem Markt agiert hat.',
      newReportPage:
        'Wählen Sie Vergleichsbanken und definieren Sie einen Auswertungszeitraum',
    },
    tooltips: {
      createPdf: 'PDF erstellen',
      printPage: 'Seite drucken',
    },
    form: {
      yourBank: 'Wettbewerber',
      compareBank: 'Vergleichen Sie bis zu 5 Banken.',
      effectiveInterestRate: 'Effektivzins für eine Beleihung von',
      period: 'Zeitraum',
      withoutLandRegistryCosts: 'Ohne Grundbuchkosten',
      debitInterestRate: 'Sollzinsbindung',
      selectCustomerGroup:
        'Wählen Sie, welcher Kundenkreis und ob Vermittlerangebote im Vergleich berücksichtigt' +
        ' werden sollen:',
      providers: {
        nationwide: 'Bundesweite Anbieter',
        regional: 'Regionale Anbieter',
        intermediary: 'Vermittler banken',
      },
      chartColors: {
        green: 'Grün',
        red: 'Rot',
        darkBlue: 'Dunkelblau',
        purple: 'Violett',
        yellow: 'Gelb',
        lime: 'Lime',
      },
      labels: {
        callMoney:
          'Vermittlerangebote in der Chart-Auswahl bei Maximum, Mittelwert und Minimum berücksichtigen',
      },
      hints: {
        buildingMoney:
          'Die Beleihung bezieht sich auf einen Kaufpreis von 250.000 Euro im Jahr 2010, der\n jährlich ' +
          'um 15.000 Euro erhöht wird.\n Immowert im Jahr 2015 = 325.000 Euro und im Jahr 2020 = 400.000 Euro\n Die ' +
          'Tilgung wurde ebenfalls entsprechend angehoben, damit eine durchschnittliche\n Finanzierung abgebildet wird.',
      },
    },
    warnings: {
      titleIsRequired: 'Bitte geben Sie den Titel des Berichts ein',
      demoUserPeriod:
        'Die Auswahl eines Zeitraums ist in der Demoversion nicht möglich.Sie sehen Daten für das Jahr 2020',
      demoUser:
        'In der Vollversion können Sie die Datenreihen für den kompletten ausgewählten Zeitraum einsehen ' +
        'und exportieren.',
    },
  },
  wizard: {
    title: 'Auswertung erstellen',
    progressBar: {
      'Musterkunden erstellen': 'Musterkunden erstellen',
      'Wettbewerber auswählen': 'Wettbewerber auswählen',
      'Anbieter auswählen': 'Wettbewerber auswählen',
      'Banken Auswahl': 'Wettbewerber auswählen',
      'Angebote filtern': 'Angebote filtern',
      'Produkte filtern': 'Angebote filtern',
      Selektion: 'Berechnungsgrundlage einstellen',
      Felderauswahl: 'Bedingungen festlegen',
      Vorschau: 'Vorschau',
      Speichern: 'Speichern',
      Fertig: 'Fertig',
      'Filter Auswahl': 'Angebote filtern',
      Spaltenauswahl: 'Kriterien festlegen',
      Laufzeiten: 'Anlagedauer auswählen',
    },
    checkBox: {
      labelAll: 'Alle/keine auswählen',
    },
    groupNames: {
      Testbanken: 'Testbanken',
      'Bundesweite Banken': ' Bundesweite Banken',
      Vermittler: 'Vermittler',
      'Bausparkassen/Versicherungen': 'Bausparkassen/Versicherungen',
      'Regionale Filialbanken': 'Regionale Filialbanken',
      Nebenbedingungen: 'Nebenbedingungen ',
      'Auswahl zu Anschlussdarlehen': 'Auswahl zu Anschlussdarlehen',
      'Seltene Kriterien': 'Seltene Kriterien',
      Direktbanken: 'Direktbanken',
      'Banken über Vermittler': 'Banken über Vermittler',
      'Bundesweite Filialbanken': 'Bundesweite Filialbanken',
      bankenRaisin: 'Banken über Raisin',
    },
    headlines: {
      secondStepTF:
        'Wählen Sie die Informationen aus, die Sie in Ihrer Auswertung sehen möchten',
      erstellen_sie_bis_zu_5_musterkunden_und_vergleichen_sie_sich_mit_ihren_wettbewerbern_konfigurieren_sie_jedes_muster_individuell_nach_ihrem_bedarf:
        'Erstellen Sie bis zu 5 Musterkunden und vergleichen Sie sich mit Ihren Wettbewerbern. Konfigurieren Sie jedes Muster individuell nach Ihrem Bedarf.',
      'sie_können_noch_weitere_vorgaben_machen:':
        'Sie können noch weitere Vorgaben machen:',
      'legen_sie_auf_basis_ihrer_auswahl_ein_automatisches_tägliches_tracking_an_ihre_auswertung_wird_jeden_tag_um_15:00_uhr_automatisch_gespeichert_sie_können_später_jeden_einzelnen_tag_ab_heute_einsehen':
        'Legen Sie auf Basis Ihrer Auswahl ein automatisches, tägliches Tracking an. Ihre Auswertung wird jeden Tag um ' +
        '15:00 Uhr automatisch gespeichert. Sie können später jeden einzelnen Tag ab heute einsehen.',
      definieren_sie_schlagwörter_zur_einfachen_suche:
        'Ergänzen Sie Tags, um den Bericht später schneller wiederzufinden',
      bitte_die_gewünschten_spalten_auswählen:
        'Wählen Sie weitere Nebenbedingungen aus (optional).',
      oder: 'oder',
      'bitte die gewünschten spalten auswählen':
        'Wählen Sie weitere Nebenbedingungen aus (optional).',
      stellen_sie_hier_selektionen_zusammen_und_fügen_diese_der_auswahl_hinzu_es_können_bis_zu_5_verschiedene_selektionen_gemacht_werden:
        'Stellen Sie hier Selektionen zusammen und fügen diese der Auswahl hinzu. Es können bis zu 5 verschiedene Selektionen gemacht werden.',
      'legen_sie_auf_basis_ihrer_auswahl_ein_automatisches_tracking_an_ihre_auswertung_wird_jeden_tag_um_15:00_uhr_automatisch_gespeichert_sie_können_später_jeden_einzelnen_tag_ab_heute_einsehen':
        'Legen Sie auf Basis Ihrer Auswahl ein automatisches Tracking an. Ihre Auswertung wird jeden Tag um 15:00 Uhr ' +
        'automatisch gespeichert. Sie können später jeden einzelnen Tag ab heute einsehen.',
      speichern_sie_die_auswertung_um_diese_momentaufnahme_von_heute_später_jederzeit_ansehen_herunterladen_oder_als_vorlage_für_weitere_auswertungen_nutzen_zu_können:
        'Speichern Sie die Auswertung, um diese Momentaufnahme von heute später jederzeit ansehen, herunterladen oder ' +
        'als Vorlage für weitere Auswertungen nutzen zu können.',
    },
    subHeadlines: {
      secondStepTCheckboxes:
        'Wählen Sie zusätzliche Spalten für Ihre Auswertung aus',
      secondStepFCheckboxes:
        'Wählen Sie die Laufzeiten aus, die in Ihre Auswertung einfließen sollen',
    },
    fields: {
      Zielgruppe: 'Nach Zielgruppe filtern',
      Kaufpreis: 'Kaufpreis',
      Darlehen: 'Darlehen',
      Beleihung: 'Beleihung',
      Zinsbindung: 'Zinsbindung',
      Tilgung: 'Tilgung',
      Volltilgung: 'Volltilgung',
      Aktionen: 'Aktionen',
      Nr: 'Nr',
      'Automatische Abspeicherung': 'Automatische Abspeicherung',
      'Einmalige Speicherung': 'Einmalige Speicherung',
      Neukunden: 'Neukunden',
      Einlagensicherung: 'Einlagensicherung',
      'Automatische tägliche Abspeicherung':
        'Automatische tägliche Abspeicherung',
      'Gewünschte Einlagensicherung': 'Gewünschte Einlagensicherung',
    },
    placeholders: {
      'Beleihung (freie Eingabe)': 'Beleihung (freie Eingabe)',
      'Kaufpreis  (freie Eingabe)': 'Kaufpreis  (freie Eingabe)',
      'Darlehen  (freie Eingabe)': 'Darlehen  (freie Eingabe)',
      'Tilgung  (freie Eingabe)': 'Tilgung  (freie Eingabe)',
      bitte_wählen: 'Bitte wählen...',
      'Geben Sie ihrer Auswahl einen Namen':
        'Geben Sie ihrer Auswahl einen Namen',
      pleaseSelect: 'Bitte wählen',
    },
    warnings: {
      bitte_titel_eingeben: 'Bitte Titel eingeben.',
      mindestens_eine_bank_muss_ausgewahlt_werden:
        'Mindestens eine Anbieter/Provider muss ausgewählt werden.',
      step1: 'Mindestens eine Checkbox muss ausgewählt werden.',
      bsugeldStep2: 'Es müssen 1-5 Produkte ausgewählt werden.',
      mindestens_eine_bank_muss_ausgewählt_werden:
        'Mindestens eine Anbieter/Provider muss ausgewählt werden.',
      mindestens_eine_laufzeit_muss_ausgewählt_werden:
        'Mindestens eine Laufzeit muss ausgewählt werden.',
      kaufpreis_muss_angegeben_werden: 'Kaufpreis muss angegeben werden',
      darlehen_muss_angegeben_werden: 'Darlehen muss angegeben werden.',
      'beleihung_muss_zwischen_10_%_und_105_%_sein':
        'Beleihung muss zwischen 10 % und 105 % sein.',
      tilgung_darf_max_100_sein: 'Tilgung darf max 100 sein.',
      zinsbindung_muss_ausgewählt_werden: 'Zinsbindung muss ausgewählt werden.',
    },
    dialogTitles: {
      configureSampleCalculation: 'Musterberechnung konfigurieren',
    },
    checkBoxValues: {
      'Basiszins-Bezug auf Beleihungsauslauf (3 Spalten)':
        'Basiszins-Bezug auf Beleihungsauslauf (3 Spalten)',
      'Rabatt bei höherer Tilgung (5 Spalten)':
        'Rabatt bei höherer Tilgung (5 Spalten)',
      'Höhe der Bereitstellungszinsen (3 Spalten)':
        'Höhe der Bereitstellungszinsen (3 Spalten)',
      'Rabatt oder Aufschlag bei unterschiedlichen Darlehensbeträgen (5 Spalten)':
        'Rabatt oder Aufschlag bei unterschiedlichen Darlehensbeträgen (5 Spalten)',
      'Möglichkeit und Aufschlag zu Sondertilgung (6 Spalten)':
        'Möglichkeit und Aufschlag zu Sondertilgung (6 Spalten)',
      'Bedingungen zu Tilgungsveränderungen (7 Spalten)':
        'Bedingungen zu Tilgungsveränderungen (7 Spalten)',
      'Vergabe von KfW-Darlehen (4 Spalten)':
        'Vergabe von KfW-Darlehen (4 Spalten)',
      'Kriterien zu Berufsgruppen (2 Spalten)':
        'Kriterien zu Berufsgruppen (2 Spalten)',
      'Forward-Aufschlag (10 Spalten)': 'Forward-Aufschlag (10 Spalten)',
      'Ergänzungen zu Anschlussdarlehen (2 Spalten)':
        'Ergänzungen zu Anschlussdarlehen (2 Spalten)',
      'Ergänzungen zu Forward-Darlehen (2 Spalten)':
        'Ergänzungen zu Forward-Darlehen (2 Spalten)',
      'Teilauszahlungen (5 Spalten)': 'Teilauszahlungen (5 Spalten)',
      'Objekt Einschränkungen (2 Spalten)':
        'Objekt Einschränkungen (2 Spalten)',
      'Angaben zu überwiegend vermieteten Objekten (2 Spalten)':
        'Angaben zu überwiegend vermieteten Objekten (2 Spalten)',
      'Basiskonditionen (2 Spalten)': 'Basiskonditionen (2 Spalten)',
      'Tilgungssätze (5 Spalten)': 'Tilgungssätze (5 Spalten)',
      'Bereitstellungszinsen (2 Spalten)': 'Bereitstellungszinsen (2 Spalten)',
      'Bedingungen bei unterschiedlichen Darlehensbeträgen (5 Spalten)':
        'Bedingungen bei unterschiedlichen Darlehensbeträgen (5 Spalten)',
      'Sondertilgung (4 Spalten)': 'Sondertilgung (4 Spalten)',
      'Tilgungssatzwechsel (6 Spalten)': 'Tilgungssatzwechsel (6 Spalten)',
      'Förderdarlehen (4 Spalten)': 'Förderdarlehen (4 Spalten)',
      'Selbstständige und Freiberufler (2 Spalten)':
        'Selbstständige und Freiberufler (2 Spalten)',
      'Forward-Darlehen (9 Spalten)': 'Forward-Darlehen (9 Spalten)',
      'Teilauszahlungen (3 Spalten)': 'Teilauszahlungen (3 Spalten)',
      'Eigentumswohnungen (2 Spalten)': 'Eigentumswohnungen (2 Spalten)',
      'Mehrfamilienhaus (1 Spalte)': 'Mehrfamilienhaus (1 Spalte)',
    },
    dropDownValues: {
      täglich: 'täglich',
      wöchentlich: 'wöchentlich',
      monatlich: 'monatlich',
    },
    tooltips: {
      produkt_zinsgutschrift:
        'Zeigt in welchen zeitlichen Abständen die Erträge während der Laufzeit gutgeschrieben werden.',
      produkt_zinsberechnung:
        'Zeigt die Zinsberechnungsmethode jedes Wettbewerbers an (z. B. deutsche, englische oder französische Methode).',
    },
  },
  nibc: {
    titles: {
      callMoney: 'NIBC-Auswertung: Tagesgeld',
      fixedDeposit: 'NIBC-Auswertung: Festgeld',
    },
  },
  kfw: {
    titles: {
      buildingMoney: 'Kfw-Auswertung: Baugeld',
    },
    explanation:
      'Änderungen der Vorgaben ab 13.06.2018: 350,000€ Kaufpreis, 315,000/280,000€ Darlehen.\nVerwendete Vorgaben für ' +
      'die Berechnung: 2% Tilgung, fertiges Objekt,Angestellter, keine Sondertilgung.\n' +
      'Die Tilgungsanhebung von 1% auf 2% erfolgte am 01.09.2015.\n\n' +
      'Bei den Vermittlern werden bei den Mittelwerten alle Bankangebote berücksichtigt, die an die FMH gemeldet werden.',
    modal: {
      averageEffectiveInterestRateText:
        'Mittelwert, bezogen auf die besten Effektivzinsen aus der FMH-Indexgruppe',
      averageDebitInterestText:
        'Mittelwert, bezogen auf die besten Sollzinsen aus der FMH-Indexgruppe',
      inFMHIndex:
        'Im FMH-IndeX werden bei Vermittlern alle passenden Angebote berücksichtigt und bei diesem Wert ' +
        'fließt nur das jeweils beste Abgebot in die Berechnung mit ein.',
    },
  },
  warnings: {
    oldReportTagesgeld: 'Dieser Report kann nicht mehr aktualisiert werden.',
    zinsChartWarning: 'Bitte ein Produkt auswählen',
    notFound: 'Nicht gefunden',
    demoUserDeletingReport:
      'Sie nutzen die kostenfreie Test-Version und dürfen keine Berichte löschen. Für ein Upgrade kontaktieren Sie uns unter sales@fmh.de.',
    demoUserEditingReport:
      'Sie nutzen die kostenfreie Test-Version und dürfen keine Berichte ändern. Für ein Upgrade kontaktieren Sie uns unter sales@fmh.de.',
  },
};

export default de;
