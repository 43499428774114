import { RootState } from '@/store/benchmark/interfaces';

export default {
  loading(state: RootState) {
    return state.loading;
  },
  error(state: RootState) {
    return state.error;
  },
  getBenchmarkData(state: RootState) {
    return state.benchmarkData;
  },
  getSnackbarData(state: RootState) {
    return state.snackbarData;
  },
  getFilters(state: RootState) {
    return state.benchmarkFilters;
  },
  getAvailableDates(state: RootState) {
    return state.availableDates;
  },
  getLoadingDates(state: RootState) {
    return state.loadingDates;
  },
  getSelectedDates(state: RootState) {
    return state.selectedDates;
  },
};
