import gql from 'graphql-tag';
import apolloProvider from '@/apollo/apollo-provider';

import { handleApiError } from '@/utils/helpers/helpers';
import { Filters } from '@/store/benchmark/interfaces';

export default {
  async fetchAvailableDates({ commit }) {
    commit('setLoadingDates', true);
    try {
      const QUERY = gql`
        query GetBankspiderGiroZeitraumliste {
          bankspiderGiroZeitraumliste {
            data
          }
        }
      `;
      const res = await apolloProvider.defaultClient.query({
        query: QUERY,
      });
      commit('setAvailableDates', res.data?.bankspiderGiroZeitraumliste?.data);
    } catch (e) {
      commit('setSnackBarData', {
        text: handleApiError(e),
        show: true,
        timeout: 3000,
        dark: false,
        color: 'red',
      });
      commit('loading', false);
    }
  },
  async fetchBenchmark({ commit }, payload: Filters) {
    const { bankType, from, to, auswahlBankkategorie, konto, transaktion } =
      payload;
    // from: left datePicker
    const dates = [from, to].filter((i) => i);

    commit('loading', true);

    const promises = dates.map(async (date) => {
      const params = {
        datum: date,
      };
      // first drop down
      if (auswahlBankkategorie.length) {
        Object.assign(params, { auswahlBankkategorie });
      }
      //checkboxes
      if (bankType.length) {
        bankType.forEach((i) => {
          Object.assign(params, { [i]: true });
        });
      }
      try {
        const QUERY = gql`
          query GetBankspiderGiroMittelwerte(
            $datum: Date
            $auswahlBundeslaender: [Int]
            $auswahlBankkategorie: [Int]
            $auswahlSparkassen: Boolean
            $auswahlGenoBanken: Boolean
          ) {
            bankspiderGiroMittelwerte(
              datum: $datum
              auswahlBundeslaender: $auswahlBundeslaender
              auswahlBankkategorie: $auswahlBankkategorie
              auswahlSparkassen: $auswahlSparkassen
              auswahlGenoBanken: $auswahlGenoBanken
            ) {
              data {
                mittelwerte {
                  bankkategorie
                  bezeichnungBankkategorie
                  anzahlBanken
                  anzahlProdukte
                  ${[...konto, ...transaktion].join('\n')}
                }
                mittelwerteGesamt {
                  bankkategorie
                  anzahlBanken
                  anzahlProdukte
                }
              }
            }
          }
        `;

        const res = await apolloProvider.defaultClient.query({
          query: QUERY,
          variables: params,
        });
        return { data: res.data?.bankspiderGiroMittelwerte?.data, date };
      } catch (e) {
        commit('setSnackBarData', {
          text: handleApiError(e),
          show: true,
          timeout: 3000,
          dark: false,
          color: 'red',
        });
        commit('loading', false);
      }
    });
    const settled = await Promise.allSettled(promises);
    const result = settled
      .filter((item) => item.status === 'fulfilled')
      .map((i) => {
        if (i.status !== 'rejected') {
          return i.value;
        }
      });
    const sortedResult = result.sort((a, b) => {
      const dateA = new Date(a?.date ?? '').getTime();
      const dateB = new Date(b?.date ?? '').getTime();
      return dateA - dateB;
    });

    commit(
      'setBenchmarkData',
      sortedResult.map((i) => i?.data)
    );
    commit('setSelectedDates', {
      from: sortedResult?.[0]?.date,
      to: sortedResult?.[1]?.date ?? '',
    });
  },
};
