<template>
  <v-app-bar app clipped-left flat id="appHeader">
    <div class="header-content">
      <img
        src="/assets/img/fmh-logo.svg"
        alt="Logo"
        width="200"
        height="40"
        @click="handleNavigate"
      />
      <div>
        <LanguageSelection v-if="isShowLanguageSelection" class="pr-3" />
        <div>
          <v-menu
            nudge-bottom="30"
            bottom
            left
            :attach="true"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon size="30" :color="color">mdi-account-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon class="base-normal-color">mdi-account</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="base-normal-color">{{
                      userData
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!--                <v-reports-item>
                  <v-row justify="end" class="languages-container">
                    <v-col v-if="isShowLanguageSelection">
                      <LanguageSelection :justFlag="false" />
                    </v-col>
                  </v-row>
                </v-reports-item>-->
              </v-list>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="color"
                  text
                  @click="logOut"
                  class="base-normal-color"
                >
                  Logout
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';
import i18n from '@/plugins/i18n';

import Button from '@/components/fmh_components/common/Buttons/Button.vue';
import Menu from '@/components/fmh_components/common/Menu/Menu.vue';
import LanguageSelection from '../fmh_components/common/LanguageSelection/LanguageSelection.vue';

import { BASE_COLOR } from '@/utils/constants';

import { IUser } from '@/interfaces';

@Component({
  components: { Menu, Button, LanguageSelection },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      logOutSession: 'auth/logOutSession',
    }),
    ...mapMutations({
      clearResult: 'reports/clearResult',
      resetError: 'reports/resetError',
      resetWizardError: 'wizard/resetError',
      clearDatenaboData: 'datenabo/clearDatenaboData',
      resetStateBProfile: 'bankingProfile/resetState',
      resetWettbewerb: 'wettbewerb/resetState',
    }),
  },
})
export default class TopBar extends Vue {
  @Prop() drawer!: Boolean;
  @Prop() mini!: Boolean;
  color = BASE_COLOR;
  title = 'header.bankingPortal';
  user?: IUser;

  clearResult!: MutationMethod;
  clearDatenaboData!: MutationMethod;
  resetStateBProfile!: MutationMethod;
  resetWettbewerb!: MutationMethod;
  logOutSession!: () => Promise<any>;

  @Watch('$route', { immediate: true }) setTitle() {
    const id = this.$router.currentRoute.params.id;
    switch (this.$router.currentRoute.fullPath) {
      case '/':
        return (this.title = 'dashboard.headerTitle');
      case '/tagesgeld':
        return (this.title = 'liveData.headerTitle.callMoney');
      case '/festgeld':
        return (this.title = 'liveData.headerTitle.fixedDeposit');
      case '/baugeld':
        return (this.title = 'liveData.headerTitle.buildingMoney');
      case '/baugeld/wizard':
        return (this.title = 'Wizard Baugeld');
      case '/festgeld/wizard':
        return (this.title = 'Wizard Festgeld');
      case '/tagesgeld/wizard':
        return (this.title = 'Wizard Tagesgeld');
      case '/wettbewerb-baugeld':
        return (this.title = 'Wettbewerb');
      case '/wettbewerb-festgeld':
        return (this.title = 'Wettbewerb');
      case '/wettbewerb-tagesgeld':
        return (this.title = 'Wettbewerb');
      case '/spezialabonnenten-tagesgeld':
        return (this.title = 'NIBC Tagesgeld');
      case '/spezialabonnenten-festgeld':
        return (this.title = 'NIBC Festgeld');
      case '/kfw-baugeld':
        return (this.title = 'KfW Baugeld');
      case '/banking-profile':
        return (this.title = 'bankingProfile.headerTitle');
      case '/banking-profile/add-new-report':
        return (this.title = 'bankingProfile.headerTitle');
      case `/banking-profile/report/${id}`:
        return (this.title = 'bankingProfile.headerTitle');
      case `/banking-profile/bank-details/${id}`:
        return (this.title = 'bankingProfile.headerTitle');
      default:
        return (this.title = 'dashboard.headerTitle');
    }
  }

  get userData() {
    return this.user?.fullName || '';
  }
  handleNavigate() {
    if (this.$router.currentRoute.path !== '/dashboard') {
      this.$router.push('/dashboard');
    }
  }
  logOut() {
    this.clearResult();
    this.clearDatenaboData();
    this.resetStateBProfile();
    this.resetWettbewerb();
    this.logOutSession().then(() => {
      this.$router.push('/login');
    });
    i18n.locale = 'de';
    this.$emit('change-drawer', { drawer: true, mini: false });
  }

  mounted() {
    const language = JSON.parse(localStorage.getItem('language') || '""');
    if (language) {
      i18n.locale = language;
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  margin-right: 20px;
}
.header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  flex-wrap: wrap;
  img:hover {
    cursor: pointer;
  }
  & > div {
    display: flex;
    align-items: center;
  }
}
.languages-container {
  [class~='col'] {
    padding-top: 0;
    padding-bottom: 0;
    [class~='v-input'] {
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.languages {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  & > .flag-icon {
    margin-right: 5px;
  }
}
</style>
