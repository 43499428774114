<template>
  <div
    :class="{
      buttonCta: type === 'cta',
      primary: type === 'primary',
      secondary: type === 'secondary',
    }"
    @click="() => method(args)"
  >
    <button :disabled="disabled" :style="addStyle">
      {{ $t(`general.actionBtn.${text}`) }}<slot></slot>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop() type!: String;
  @Prop({ default: false }) disabled: Boolean | undefined;
  @Prop() method!: Function;
  @Prop() text!: String;
  @Prop() addStyle!: Object;
  @Prop() args: Object | undefined;
}
</script>

<style lang="scss" scoped>
.buttonCta {
  margin-right: 5px;
  button {
    cursor: pointer;
    user-select: none;
    font-family: Roboto-Medium, sans-serif;
    height: 40px;
    padding: 0 10px 0 10px;
    text-transform: uppercase;
    font-size: inRem(14px);
    background-color: $text;
    border: none;
    border-radius: 5px;
    color: $white;
    margin: 10px 0 10px 0;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
      0 1px 18px 0 rgb(0 0 0 / 12%);
  }

  button:hover {
    opacity: 0.9;
  }

  button:active {
    background-color: $text;
  }

  button:focus {
    outline: none;
  }

  button:disabled {
    background-color: $secondary-a;
    color: $secondary-glow;
    cursor: initial;
  }
}

.primary {
  height: 36px;
  margin-left: 10px;

  button {
    cursor: pointer;
    user-select: none;
    font-family: Roboto-Medium, sans-serif;
    width: 100%;
    height: 100%;
    padding: 0 10px 0 10px;
    text-transform: uppercase;
    font-size: inRem(14px);
    background-color: $text;
    border: none;
    border-radius: 5px;
    color: $white;
  }

  button:hover {
    opacity: 0.8;
  }

  button:active {
    background-color: $text;
  }

  button:focus {
    outline: none;
  }

  button:disabled {
    background-color: $base-color;
    color: grey;
    cursor: initial;
  }
}

.secondary {
  height: 36px;
  margin-left: 10px;

  button {
    cursor: pointer;
    user-select: none;
    font-family: Roboto-Medium, sans-serif;
    width: 100%;
    height: 100%;
    padding: 0 10px 0 10px;
    text-transform: uppercase;
    font-size: inRem(14px);
    background-color: $white;
    border: 1px solid $text;
    border-radius: 5px;
    color: #fff;
  }

  button:hover {
    background-color: $very-light-gray;
  }

  button:active {
    background-color: $light-gray;
  }

  button:focus {
    outline: none;
  }

  button:disabled {
    border: 1px solid $very-soft-blue;
    color: #a1d4ef;
    cursor: initial;
    background-color: inherit;
  }
}
@media screen and (max-width: 630px) {
  .buttonCta {
    button {
      font-size: 14px;
    }
  }
}
</style>
